


















































import { SfButton, SfSearchBar } from "@storefront-ui/vue";
import { defineComponent, ref, watch, useRoute, toRefs, useRouter } from "@nuxtjs/composition-api";
import { debounce, isEmpty } from "lodash-es";
import { clickOutside } from "~/components/directives/click-outside/click-outside-directive";
import SvgImage from "~/components/General/SvgImage.vue";
import { useProduct } from "~/modules/catalog/product/composables/useProduct";
import { Products } from "~/modules/GraphQL/types";
import TermsConditionsVue from "~/pages/Terms-Conditions.vue";

export default defineComponent({
  name: "SearchBarHeader",
  components: {
    SfSearchBar,
    SfButton,
    SvgImage,
  },
  directives: { clickOutside },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
    term: {
      type: String,
      default: "",
    },
  },
  emits: ["set-is-open", "set-search-results","searchTerm"],
  setup(props, { emit }) {
    const { term } = toRefs(props);
    const route = useRoute();
    const { getProductList } = useProduct();
    const router = useRouter();
    const showSearch = () => {
      if (!props.isSearchOpen) {
        emit("set-is-open", true);
        if (document) {
          document.body.classList.add("no-scroll");
        }
      }
    };

    const hideSearch = () => {
      if (props.isSearchOpen) {
        emit("set-is-open", false);
        emit("set-search-results", null);
        if (document) {
          document.body.classList.remove("no-scroll");
        }
      }
    };

    const toggleSearch = () => {
      if (props.isSearchOpen) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    const closeSearch = (event: MouseEvent) => {
      if (document) {
        const searchResultsEl = document.querySelector(".search");
        const closeTriggerElement = event.target as HTMLElement;

        if (!searchResultsEl?.contains(closeTriggerElement)) {
          hideSearch();
        }
      } else {
        hideSearch();
      }
    };

    const rawHandleSearch = async (searchTerm: string) => {
      emit("searchTerm", searchTerm);
      if (searchTerm.length < props.minTermLen) return;
    };

    const debouncedHandleSearch = debounce(rawHandleSearch, 100);

    const handleKeydownEnter = (searchTerm: string) => {
      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      debouncedHandleSearch.cancel();
      rawHandleSearch(searchTerm);
      if(!isEmpty(searchTerm))
      router.push("/catalogsearch/result/?term="+searchTerm);
    };

    watch(route, () => {
      hideSearch();
    });

    return {
      closeSearch,
      showSearch,
      hideSearch,
      toggleSearch,
      rawHandleSearch,
      debouncedHandleSearch,
      handleKeydownEnter,
      
    };
  },
});
