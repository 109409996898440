



















import { SfModal, SfAlert, SfLink } from "@storefront-ui/vue";
import { defineComponent } from "@nuxtjs/composition-api";
import { useUiState } from "~/composables";

export default defineComponent({
  name: "RegisterSuccess",
  components: {
    SfModal,
    SfAlert,
    SfLink,
  },
  setup() {
    const { isRegisterSuccessModalOpen, toggleRegisterSuccessModal } =
      useUiState();
    return {
      isRegisterSuccessModalOpen,
      toggleRegisterSuccessModal,
    };
  },
});
