














































































































import { SfOverlay, SfHeader, SfButton, SfBadge } from "@storefront-ui/vue";

import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
  onActivated,
  onUpdated,
} from "@nuxtjs/composition-api";
import HeaderNavigation from "~/components/Header/Navigation/HeaderNavigation.vue";
import { useCategory } from "~/modules/catalog/category/composables/useCategory";
import { useUiHelpers, useUiState } from "~/composables";
import { useCart } from "~/modules/checkout/composables/useCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useUser } from "~/modules/customer/composables/useUser";
import { useWishlistStore } from "~/modules/wishlist/store/wishlistStore";
import type { CategoryTree, ProductInterface } from "~/modules/GraphQL/types";
import HeaderLogo from "~/components/HeaderLogo.vue";
import SvgImage from "~/components/General/SvgImage.vue";
import { useTopBar } from "./TopBar/useTopBar";
import { isEmpty } from "lodash-es";
import SearchBarHeader from "~/components/Header/SearchBar/SearchBarHeader.vue";
import CategoryProductGridSearch from "~/modules/catalog/category/components/views/CategoryProductGridSearch.vue";
import CurrencySelector from "~/components/CurrencySelector.vue";
import StoreSwitcher from "~/components/StoreSwitcher.vue";

export default defineComponent({
  components: {
    HeaderNavigation,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    SearchBarHeader,
    CategoryProductGridSearch,
    CurrencySelector,
    StoreSwitcher,
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleLoginModal,
    } = useUiState();
    const { setTermForUrl, getCatLink, getFacetsFromURL } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } =
      useCategory();

    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const searchTerm = ref("");
    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(
      () => wishlistStore.wishlist?.items_count ?? 0
    );

    const accountIcon = computed(() =>
      isAuthenticated.value ? "profile_fill" : "profile"
    );
    const categoryTree = ref<CategoryTree[]>([]);

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push("/customer/my-profile");
      } else {
        toggleLoginModal();
      }
    };
    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children.filter(
        (category) => category.include_in_menu
      );
    });

    onMounted(async () => {
      searchTerm.value = getFacetsFromURL().term;
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        await loadWishlistItemsCount();
      }
    });
    const redirectUrlPath = () => {
      if (!isEmpty(searchTerm.value))
        router.push("/catalogsearch/result/?term=" + searchTerm.value);
    };
    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
      searchTerm,
      redirectUrlPath,
      getFacetsFromURL,
    };
  },
  watch:{
    $route (){
      this.searchTerm = this.getFacetsFromURL().term;
    }
  },
});
