










































import { SfFooter, SfLink } from "@storefront-ui/vue";
import { defineComponent } from "@nuxtjs/composition-api";
import { addBasePath } from "~/helpers/addBasePath";

export default defineComponent({
  components: {
    SfFooter,
    SfLink,
  },
  setup() {
    const link = [
      "terms-conditions",
      "faq",
      "privacy-policy",
      "catalog-request-form",
      "contact",
    ];
    const linkindex = (index) => {
      return link[index];
    };
    return {
      addBasePath,
      linkindex,
    };
  },
  data() {
    return {
      departments: [
        "Terms & Conditions",
        "FAQ's",
        "Privacy Policy",
        "Request a Catalog",
        "",
      ],
      social: ["facebook", "pinterest", "google", "twitter", "youtube"],
      isMobile: false,
      desktopMin: 1024,
    };
  },
});
