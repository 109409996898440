import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3d32c502 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _3e750100 = () => interopDefault(import('../pages/AboutUs.vue' /* webpackChunkName: "" */))
const _2c1dac6c = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _849913de = () => interopDefault(import('../pages/Catalog-Request-Form.vue' /* webpackChunkName: "" */))
const _1becad56 = () => interopDefault(import('../pages/Catalogs.vue' /* webpackChunkName: "" */))
const _fac35ca4 = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "" */))
const _5796d3dc = () => interopDefault(import('../pages/Distributor.vue' /* webpackChunkName: "" */))
const _23b93f3d = () => interopDefault(import('../pages/Error.vue' /* webpackChunkName: "" */))
const _1311c08b = () => interopDefault(import('../pages/FAQ.vue' /* webpackChunkName: "" */))
const _32ecb60f = () => interopDefault(import('../pages/PrivacyPolicy.vue' /* webpackChunkName: "" */))
const _68256c5c = () => interopDefault(import('../pages/StoreFinder.vue' /* webpackChunkName: "" */))
const _3ee56dd8 = () => interopDefault(import('../pages/TermsConditions.vue' /* webpackChunkName: "" */))
const _749a8192 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _1fbf4e44 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _e260aa2e = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _53a4f95b = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _11860d76 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _7d592d84 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _1c92718f = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _fcbcc806 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _75853972 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _23f31e4c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyPaymentMethods.vue' /* webpackChunkName: "" */))
const _3522118e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _3b0c347d = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _25af53d3 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _5b625f3d = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _58f9e289 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _b0b64790 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _5371011e = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _00b3c8d4 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _12fa9663 = () => interopDefault(import('../pages/FindDistributor.vue' /* webpackChunkName: "pages/FindDistributor" */))
const _5a154c8c = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _232a6f6a = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _0aa2bc96 = () => interopDefault(import('../pages/SeoSitemapCatalog.vue' /* webpackChunkName: "pages/SeoSitemapCatalog" */))
const _247a6328 = () => interopDefault(import('../pages/SeoSitemapProduct.vue' /* webpackChunkName: "pages/SeoSitemapProduct" */))
const _6804787f = () => interopDefault(import('../modules/catalog/pages/categorySearch.vue' /* webpackChunkName: "" */))
const _4f73a386 = () => interopDefault(import('../modules/customer/components/LoginModal/forms/ForgotPasswordForm.vue' /* webpackChunkName: "" */))
const _6e761eb0 = () => interopDefault(import('../modules/checkout/components/Error.vue' /* webpackChunkName: "" */))
const _8661fdd4 = () => interopDefault(import('../modules/checkout/components/VsfPaymentProvider.vue' /* webpackChunkName: "" */))
const _342ed814 = () => interopDefault(import('../modules/checkout/pages/Checkout/paypal/Success.vue' /* webpackChunkName: "" */))
const _084830be = () => interopDefault(import('../modules/customer/pages/MyAccount/Create.vue' /* webpackChunkName: "" */))
const _146ce036 = () => interopDefault(import('../modules/customer/pages/Login.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ ",
    component: _3d32c502,
    name: "checkout/thank-you___"
  }, {
    path: "/about-us.html",
    component: _3e750100,
    name: "about-us___"
  }, {
    path: "/cart",
    component: _2c1dac6c,
    name: "cart___absimporttools___"
  }, {
    path: "/Catalog-Request-Form.html",
    component: _849913de,
    name: "Catalog-Request-Form____absimporttools___"
  }, {
    path: "/catalogs.html",
    component: _1becad56,
    name: "catalog___absimporttools___"
  }, {
    path: "/contact-us.html",
    component: _fac35ca4,
    name: "contact-us___absimporttools___"
  }, {
    path: "/distributors-2111.html",
    component: _5796d3dc,
    name: "distributor___absimporttools___"
  }, {
    path: "/error.html",
    component: _23b93f3d,
    name: "error_find____absimporttools___"
  }, {
    path: "/faq.html",
    component: _1311c08b,
    name: "faq___"
  }, {
    path: "/privacy-policy.html",
    component: _32ecb60f,
    name: "privacy-policy___absimporttools___"
  }, {
    path: "/storelocator.html",
    component: _68256c5c,
    name: "storelocator___absimporttools___"
  }, {
    path: "/terms-conditions.html",
    component: _3ee56dd8,
    name: "terms-conditions___absimporttools___"
  }, {
    path: "//AboutUs",
    component: _3e750100,
    name: "AboutUs___"
  }, {
    path: "//cart",
    component: _2c1dac6c,
    name: "cart___"
  }, {
    path: "//Catalog-Request-Form",
    component: _849913de,
    name: "Catalog-Request-Form___"
  }, {
    path: "//Catalogs",
    component: _1becad56,
    name: "Catalogs___"
  }, {
    path: "//checkout",
    component: _749a8192,
    name: "checkout___",
    children: [{
      path: "billing",
      component: _1fbf4e44,
      name: "billing___"
    }, {
      path: "payment",
      component: _e260aa2e,
      name: "payment___"
    }, {
      path: "shipping",
      component: _53a4f95b,
      name: "shipping___"
    }, {
      path: "thank-you",
      component: _3d32c502,
      name: "thank-you___"
    }, {
      path: "user-account",
      component: _11860d76,
      name: "user-account___"
    }]
  }, {
    path: "//Cms",
    component: _7d592d84,
    name: "Cms___"
  }, {
    path: "//Contacts",
    component: _fac35ca4,
    name: "Contacts___"
  }, {
    path: "//customer",
    component: _1c92718f,
    meta: {"titleLabel":"My Account"},
    name: "customer___",
    children: [{
      path: "addresses-details",
      component: _fcbcc806,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___"
    }, {
      path: "my-newsletter",
      component: _75853972,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___"
    }, {
      path: "my-payment-methods",
      component: _23f31e4c,
      meta: {"titleLabel":"My Payment Methods"},
      props: true,
      name: "my-payment-methods___"
    }, {
      path: "my-profile",
      component: _3522118e,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___"
    }, {
      path: "my-reviews",
      component: _3b0c347d,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___"
    }, {
      path: "my-wishlist",
      component: _25af53d3,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___"
    }, {
      path: "order-history",
      component: _5b625f3d,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___"
    }, {
      path: "addresses-details/create",
      component: _58f9e289,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___"
    }, {
      path: "//reset-password",
      component: _b0b64790,
      alias: "/customer/account/createPassword",
      name: "reset-password___"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _5371011e,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___"
    }, {
      path: "order-history/:orderId",
      component: _00b3c8d4,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___"
    }]
  }, {
    path: "//Distributor",
    component: _5796d3dc,
    name: "Distributor___"
  }, {
    path: "//Error",
    component: _23b93f3d,
    name: "Error___"
  }, {
    path: "//FAQ",
    component: _1311c08b,
    name: "FAQ___"
  }, {
    path: "//FindDistributor",
    component: _12fa9663,
    name: "FindDistributor___"
  }, {
    path: "//Home",
    component: _5a154c8c,
    name: "Home___"
  }, {
    path: "//Page",
    component: _232a6f6a,
    name: "Page___"
  }, {
    path: "//PrivacyPolicy",
    component: _32ecb60f,
    name: "PrivacyPolicy___"
  }, {
    path: "//SeoSitemapCatalog",
    component: _0aa2bc96,
    name: "SeoSitemapCatalog___"
  }, {
    path: "//SeoSitemapProduct",
    component: _247a6328,
    name: "SeoSitemapProduct___"
  }, {
    path: "//StoreFinder",
    component: _68256c5c,
    name: "StoreFinder___"
  }, {
    path: "//TermsConditions",
    component: _3ee56dd8,
    name: "TermsConditions___"
  }, {
    path: "/catalogsearch/result",
    component: _6804787f,
    name: "result search___absimporttools___"
  }, {
    path: "/customer/forgot_pass.html",
    component: _4f73a386,
    name: "fogot___absimporttools___"
  }, {
    path: "/seo-sitemap/catalog.html",
    component: _0aa2bc96,
    name: "seo_sitemap___absimporttools___"
  }, {
    path: "/seo-sitemap/product.html",
    component: _247a6328,
    name: "seo_sitemap_product___absimporttools___"
  }, {
    path: "/checkout/amazon/cancel",
    component: _6e761eb0,
    name: "amazon_cancel____absimporttools___"
  }, {
    path: "/checkout/amazon/success",
    component: _8661fdd4,
    name: "amazon_success___absimporttools___"
  }, {
    path: "/checkout/payment/error",
    component: _6e761eb0,
    name: "payment_error____absimporttools___"
  }, {
    path: "/checkout/paypal/cancel",
    component: _6e761eb0,
    name: "paypal_cancel____absimporttools___"
  }, {
    path: "/checkout/paypal/success",
    component: _342ed814,
    name: "paypal_success___absimporttools___"
  }, {
    path: "/checkout/stripe/error.html",
    component: _6e761eb0,
    name: "payment_error___absimporttools___"
  }, {
    path: "/customer/account/create",
    component: _084830be,
    name: "create-account___absimporttools___"
  }, {
    path: "/customer/account/login.html",
    component: _146ce036,
    name: "login___absimporttools___"
  }, {
    path: "/customer/addresses-details/create",
    component: _58f9e289,
    name: "addresses-details/create___"
  }, {
    path: "/",
    component: _5a154c8c,
    name: "home___absimporttools___"
  }, {
    path: "/:slug+",
    component: _232a6f6a,
    name: "page___absimporttools___"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
