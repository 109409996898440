import { UseContextReturn } from '~/types/core';
import type { PlaceOrderOutput } from '~/modules/GraphQL/types';
import { ComposableFunctionArgs } from '~/composables';
import { Logger } from '~/helpers/logger';

export const placeOrderCommand = {
  execute: async (context: UseContextReturn, cartId: string, params?: ComposableFunctionArgs<{}>): Promise<PlaceOrderOutput | null> => {
    const { data } = await context.app.$vsf.$magento.api.placeOrder(
      { cart_id: cartId },
      params?.customQuery ?? null,
      params?.customHeaders,
    );
    console.log("placeOrderCommand params",params)
    console.log("placeOrderCommand cartId",cartId)
    console.log("placeOrderCommand",data)
    Logger.info("placeOrderCommand params",params)
    Logger.info("placeOrderCommand cartId",cartId)
    Logger.info("placeOrderCommand",data)
    return data?.placeOrder ?? null;
  },
};
