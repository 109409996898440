






























import LazyHydrate from "vue-lazy-hydration";
import { useRoute, defineComponent } from "@nuxtjs/composition-api";
import { useUiState } from "~/composables";
import AppHeader from "~/components/AppHeader.vue";
import BottomNavigation from "~/components/BottomNavigation.vue";
import IconSprite from "~/components/General/IconSprite.vue";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import AppFooter from "~/components/AppFooter.vue";
import CartSidebar from "~/modules/checkout/components/CartSidebar.vue";
import WishlistSidebar from "~/modules/wishlist/components/WishlistSidebar.vue";
import LoginModal from "~/modules/customer/components/LoginModal/LoginModal.vue";
import Notification from "~/components/Notification.vue";
import RegisterSuccess from "~/modules/customer/components/RegisterSuccess/RegisterSuccess.vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default defineComponent({
  name: "DefaultLayout",

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    IconSprite,
    AppFooter,
    CartSidebar,
    WishlistSidebar,
    LoginModal,
    Notification,
    RegisterSuccess,
  },

  setup() {
    const route = useRoute();
    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      isForgotModalOpen,
      toggleForgotModal,
    } = useUiState();
    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
      toggleForgotModal,
      isForgotModalOpen,
    };
  },
  head: {
    // link: [{ rel: 'stylesheet', href: '/_nuxt/css/fonts.css' }],
  },
});
